import { useMemo } from 'react';

import {
  Apartment,
  AttachMoney,
  BarChart,
  Campaign,
  History,
  ManageAccountsOutlined,
  SettingsOutlined,
  SupportAgent,
  TrendingUp,
} from '@mui/icons-material';

import { useUser } from 'hooks';
import { routes } from 'router';

import Navigation from './navigation';

const NavigationContainer = () => {
  const { canAccessMediaApprovalPage, canUpdateTags } = useUser();

  const links = useMemo(
    () => [
      {
        to: routes.dashboard.path,
        text: routes.dashboard.name,
        icon: <TrendingUp />,
      },
      {
        to: routes.campaign.path,
        text: routes.campaign.name,
        icon: <Campaign />,
        subLinks: [
          {
            to: routes.campaignManagement.path,
            text: routes.campaignManagement.name,
          },
          {
            to: routes.externalCampaign.path,
            text: routes.externalCampaign.name,
          },
          {
            to: routes.shoppingCampaign.path,
            text: routes.shoppingCampaign.name,
          },
          {
            to: routes.removedCampaign.path,
            text: routes.removedCampaign.name,
          },
          {
            to: routes.campaignStatus.path,
            text: routes.campaignStatus.name,
          },
        ],
      },
      {
        to: routes.media.path,
        text: routes.media.name,
        icon: <Apartment />,
        subLinks: [
          {
            to: routes.mediaManagement.path,
            text: routes.mediaManagement.name,
          },
          ...(canAccessMediaApprovalPage
            ? [
                {
                  to: routes.mediaApproval.path,
                  text: routes.mediaApproval.name,
                },
              ]
            : []),
          {
            to: routes.mediaPriceManagement.path,
            text: routes.mediaPriceManagement.name,
          },
          {
            to: routes.mediaPlatformManagement.path,
            text: routes.mediaPlatformManagement.name,
          },
        ],
      },
      {
        to: routes.operation.path,
        text: routes.operation.name,
        icon: <ManageAccountsOutlined />,
        subLinks: [
          {
            to: routes.monitoring.path,
            text: routes.monitoring.name,
          },
          {
            to: routes.priorityManagement.path,
            text: routes.priorityManagement.name,
          },
          {
            to: routes.targetingManagement.path,
            text: routes.targetingManagement.name,
          },
          ...(canUpdateTags
            ? [
                {
                  to: routes.tagManagement.path,
                  text: routes.tagManagement.name,
                },
              ]
            : []),
          {
            to: routes.newsWebtoonRcpmManagement.path,
            text: routes.newsWebtoonRcpmManagement.name,
          },
          {
            to: routes.whiteAdidManagement.path,
            text: routes.whiteAdidManagement.name,
          },
        ],
      },
      {
        to: routes.report.path,
        text: routes.report.name,
        icon: <BarChart />,
        subLinks: [
          {
            to: routes.salesReport.path,
            text: routes.salesReport.name,
          },
          {
            to: routes.campaignReport.path,
            text: routes.campaignReport.name,
          },
          {
            to: routes.mediaReport.path,
            text: routes.mediaReport.name,
          },
        ],
      },
      {
        to: routes.settlement.path,
        text: routes.settlement.name,
        icon: <AttachMoney />,
        subLinks: [
          {
            to: routes.campaignSettlement.path,
            text: routes.campaignSettlement.name,
          },
          {
            to: routes.companySettlement.path,
            text: routes.companySettlement.name,
          },
          {
            to: routes.companyRevenueDetail.path,
            text: routes.companyRevenueDetail.name,
          },
        ],
      },
      {
        to: routes.cs.path,
        text: routes.cs.name,
        icon: <SupportAgent />,
        subLinks: [
          {
            to: routes.csManagement.path,
            text: routes.csManagement.name,
          },
          {
            to: routes.bulkCSManagement.path,
            text: routes.bulkCSManagement.name,
          },
          {
            to: routes.csDashboard.path,
            text: routes.csDashboard.name,
          },
          {
            to: routes.csParticipationManagement.path,
            text: routes.csParticipationManagement.name,
          },
          {
            to: routes.qaParticipationCancellation.path,
            text: routes.qaParticipationCancellation.name,
          },
        ],
      },
      {
        to: routes.management.path,
        text: routes.management.name,
        icon: <SettingsOutlined />,
        subLinks: [
          {
            to: routes.companyAndCustomer.path,
            text: routes.companyAndCustomer.name,
          },
          {
            to: routes.faqContent.path,
            text: routes.faqContent.name,
          },
        ],
      },
      {
        to: routes.log.path,
        text: routes.log.name,
        icon: <History />,
        subLinks: [
          {
            to: routes.systemLog.path,
            text: routes.systemLog.name,
          },
          {
            to: routes.campaignLog.path,
            text: routes.campaignLog.name,
          },
        ],
      },
    ],
    [canAccessMediaApprovalPage, canUpdateTags]
  );

  return <Navigation links={links} />;
};

export default NavigationContainer;
