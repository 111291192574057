import { useCallback } from 'react';

import { MenuItem, Select, SelectChangeEvent, SxProps, Theme, Typography } from '@mui/material';

import { Option } from './types';

export type SelectTableOptionsProps<T> = {
  options: Option<T>[];
  value: T;
  onChange: (value: T | string) => void;
  label?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

const SelectTableOptions = <T extends string | number>(props: SelectTableOptionsProps<T>) => {
  const { value, onChange, options, label = '옵션', disabled = false, sx = [] } = props;

  const renderSelectedStatus = useCallback(
    (selectedValue: T) => {
      const text = options.find(({ value }) => value === selectedValue)?.text;

      return (
        <Typography variant="body2">
          {label}: {text}
        </Typography>
      );
    },
    [label, options]
  );

  const handleSelectStatus = useCallback(
    (e: SelectChangeEvent<T>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Select
      value={value}
      onChange={handleSelectStatus}
      disabled={disabled}
      renderValue={renderSelectedStatus}
      sx={[
        {
          minWidth: 120,
          width: 180,
          height: 36,
          typography: 'body2',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {options.map((option, idx) => (
        <MenuItem
          key={idx}
          value={option.value}
          sx={{
            maxWidth: 1,
            height: 32,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {option.text}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTableOptions;
