import { Autorenew, Search } from '@mui/icons-material';
import { Box, Button, Stack, TextField } from '@mui/material';

import { rotateAnimation } from 'styles';

import { SearchFormProps } from './types';

const SearchForm = (props: SearchFormProps) => {
  const { isFetching = false, onClickSearch, width, sx = [], ...textFieldProps } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ width: width || 300, position: 'relative' }}>
        <TextField
          type="text"
          variant="outlined"
          autoComplete="off"
          sx={[
            {
              width: 1,
              height: 36,
              '.MuiInputBase-root': {
                height: 1,
                typography: 'body2',
              },
              '.MuiInputBase-input': {
                pr: 10,
              },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...textFieldProps}
        />
        <Button
          type="submit"
          onClick={onClickSearch}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 8,
            transform: 'translateY(-50%)',
            minWidth: 0,
            width: 24,
            height: 24,
            p: 0,
            overflow: 'hidden',
          }}
        >
          <Search />
        </Button>
      </Box>
      {isFetching && (
        <Autorenew fontSize="small" sx={{ animation: `${rotateAnimation} 1s infinite` }} />
      )}
    </Stack>
  );
};

export default SearchForm;
