import { IntegrationType, ParticipationType } from 'api';
import { SSO, fullWidth } from 'styles';

import { ParticipationTypeCategory } from './types';

export const labelStyle: SSO = {
  mb: 1,
  color: 'text.primary',
};

export const textFieldStyle: SSO[] = [
  fullWidth,
  {
    '.MuiInputBase-input': {
      p: 3,
    },
  },
];

export const participationTypeByCategory: {
  [K in ParticipationTypeCategory]: ParticipationType[];
} = {
  [ParticipationTypeCategory.기본]: [
    ParticipationType.클릭형,
    ParticipationType['컨텐츠 클릭형'],
    ParticipationType.회원가입형,
    ParticipationType.실행형,
    ParticipationType.설치형,
    ParticipationType['비디오 시청형'],
    ParticipationType.달성형,
    ParticipationType['TCPA 달성형'],
    ParticipationType['SSP 플랫폼 구매형'],
    ParticipationType['사전 예약'],
    ParticipationType.CPS,
    ParticipationType.CPC20,
    ParticipationType['웹 CPC20(스크립트)'],
    ParticipationType.CPQ,

    ParticipationType['멀티리워드_설치형'],
    ParticipationType['멀티리워드_실행형'],
    ParticipationType['멀티리워드_달성형'],
  ],
  [ParticipationTypeCategory.SNS]: [
    ParticipationType['페이스북 좋아요'],
    ParticipationType['인스타그램 팔로우'],
    ParticipationType['인스타그램 좋아요'],
    ParticipationType['유튜브 구독형'],
    ParticipationType['유튜브 구독 및 좋아요'],
    ParticipationType['네이버 구독형'],
    ParticipationType['카카오톡 채널 구독형'],
    ParticipationType['네이버 카페 구독형'],
    ParticipationType['네이버 스토어 찜 구독형'],
    ParticipationType['네이버 쇼핑 라이브 구독형'],
    ParticipationType['네이버 TV 구독형'],
    ParticipationType['네이버 포스트 구독형'],
    ParticipationType['Tiktok 팔로우'],
    ParticipationType['유튜브 시청형'],
    ParticipationType['DAUM 뉴스 구독'],
    ParticipationType['네이버 쇼핑 퀴즈'],
    ParticipationType['네이버 플레이스 저장'],
    ParticipationType['네이버 플레이스 검색&저장'],
    ParticipationType['웹툰'],
    ParticipationType['앱 사전등록'],
    ParticipationType['멀티리워드_인스타그램 팔로우'],
    ParticipationType['멀티리워드_인스타그램 좋아요'],
    ParticipationType['멀티리워드_유튜브 구독형'],
    ParticipationType['멀티리워드_유튜브 구독&좋아요'],
    ParticipationType['멀티리워드_페이스북 좋아요'],
    ParticipationType['멀티리워드_네이버 구독형'],
    ParticipationType['멀티리워드_DAUM 뉴스 구독'],
    ParticipationType['멀티리워드_카카오톡 채널 구독형'],
    ParticipationType['멀티리워드_틱톡 팔로우'],
    ParticipationType['동영상 시청 & 페이지 방문'],
  ],
  [ParticipationTypeCategory['간편 적립']]: [],
};

export const integrationTypeByParticipation: {
  [K in ParticipationType]?: IntegrationType[];
} = {
  [ParticipationType.클릭형]: [
    IntegrationType.Adpopcorn,
    IntegrationType.Outsourcing, // deprecated
  ],
  [ParticipationType.회원가입형]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.IVE,
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.GENIEWORKS,
    IntegrationType.LINKPRICE,
    IntegrationType.FLEX,
    IntegrationType.Outsourcing, // deprecated
    IntegrationType.NSTATION,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType.실행형]: [
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.IVE,
    IntegrationType.PINCRUX,
    IntegrationType.LINKPRICE,
    IntegrationType.FLEX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.NSTATION,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType.설치형]: [
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.LINKPRICE,
    IntegrationType.FLEX,
    IntegrationType.IVE,
    IntegrationType.PINCRUX,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['비디오 시청형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.Outsourcing, // deprecated
  ],
  [ParticipationType.달성형]: [
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_ADPOPCORN_SDK,
    IntegrationType.POSTBACK_MACHINEZONE,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.LINKPRICE,
    IntegrationType.FLEX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.IVE,
    IntegrationType.PINCRUX,
    IntegrationType.NSTATION,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['TCPA 달성형']]: [
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_ADPOPCORN_SDK,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
  ],
  [ParticipationType['SSP 플랫폼 구매형']]: [IntegrationType.POSTBACK_BRANCH],
  [ParticipationType['사전 예약']]: [
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.IVE,
  ],
  [ParticipationType.CPS]: [
    IntegrationType.Adpopcorn,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.GENIEWORKS,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.THEZOOOM,
    IntegrationType.FLEX,
    IntegrationType.JINKOON,
  ],
  [ParticipationType.CPC20]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
  ],
  [ParticipationType['웹 CPC20(스크립트)']]: [IntegrationType.Adpopcorn],
  [ParticipationType.CPQ]: [
    IntegrationType.SECRET_K,
    IntegrationType.HELPSTORE_R,
    IntegrationType.JCURVE,
    IntegrationType.POMISSION,
    IntegrationType.APPSOLUTION,
    IntegrationType.ALINE,
    IntegrationType.KEYWORDLAB,
    IntegrationType.GOLDENDUCK,
    IntegrationType.REWARDBOOM,
  ],
  // [ParticipationType.CPM]: [IntegrationType.BUZZ_AD],
  [ParticipationType['페이스북 좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['인스타그램 팔로우']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['유튜브 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  // 네이버 뉴스
  [ParticipationType['네이버 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
  ],
  // 카카오톡 채널
  [ParticipationType['카카오톡 채널 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['네이버 카페 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
  ],
  [ParticipationType['네이버 스토어 찜 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.IVE,
  ],
  [ParticipationType['네이버 쇼핑 라이브 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.IVE,
  ],
  [ParticipationType['네이버 TV 구독형']]: [IntegrationType.Adpopcorn],
  [ParticipationType['네이버 포스트 구독형']]: [IntegrationType.Adpopcorn],
  [ParticipationType['Tiktok 팔로우']]: [IntegrationType.Adpopcorn, IntegrationType.NSTATION],
  [ParticipationType['유튜브 시청형']]: [IntegrationType.Adpopcorn],
  [ParticipationType['DAUM 뉴스 구독']]: [IntegrationType.Adpopcorn],
  [ParticipationType['컨텐츠 클릭형']]: [IntegrationType.Adpopcorn],
  [ParticipationType['웹툰']]: [IntegrationType.Adpopcorn],
  [ParticipationType['네이버 쇼핑 퀴즈']]: [IntegrationType.Adpopcorn],
  [ParticipationType['네이버 플레이스 저장']]: [IntegrationType.Adpopcorn],
  [ParticipationType['네이버 플레이스 검색&저장']]: [IntegrationType.Adpopcorn],
  [ParticipationType['앱 사전등록']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM,
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA,
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE,
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
  ],
  [ParticipationType['인스타그램 좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['유튜브 구독 및 좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.APPANG,
    IntegrationType.OHC,
    IntegrationType.BUZZ_AD,
    IntegrationType.FOCUSM, // deprecated
    IntegrationType.TNK,
    IntegrationType.NSWITCH,
    IntegrationType.PICA, // deprecated
    IntegrationType.PINCRUX,
    IntegrationType.MINWISE, // deprecated
    IntegrationType.PICKNSHARE,
    IntegrationType.LINKPRICE,
    IntegrationType.IVE,
    IntegrationType.NSTATION,
  ],
  [ParticipationType['멀티리워드_설치형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_실행형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_달성형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.ADBRIX, // deprecated
    IntegrationType.POSTBACK_ADBRIX,
    IntegrationType.POSTBACK_APPSFLYER,
    IntegrationType.POSTBACK_ADJUST,
    IntegrationType.POSTBACK_SINGULAR,
    IntegrationType.POSTBACK_KOCHAVA,
    IntegrationType.POSTBACK_AIRBRIDGE,
    IntegrationType.POSTBACK_BRANCH,
    IntegrationType.POSTBACK_WISETRACKER,
    IntegrationType.POSTBACK_ADPOPCORN_SDK,
    IntegrationType.POSTBACK_MACHINEZONE,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_인스타그램 팔로우']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_인스타그램 좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_유튜브 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_유튜브 구독&좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_페이스북 좋아요']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_네이버 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_DAUM 뉴스 구독']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_카카오톡 채널 구독형']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['멀티리워드_틱톡 팔로우']]: [
    IntegrationType.Adpopcorn,
    IntegrationType.GMO_TECH,
  ],
  [ParticipationType['동영상 시청 & 페이지 방문']]: [IntegrationType.Adpopcorn],
};
